import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';
import { useSyncExternalStore } from 'react';


const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyDt-QGhurv01qjsrpv9KX-nFCA0ywUvi3k",
    authDomain: "bitgate-ai.firebaseapp.com",
    projectId: "bitgate-ai",
    storageBucket: "bitgate-ai.firebasestorage.app",
    messagingSenderId: "611668947989",
    appId: "1:611668947989:web:66e09e2dec3d8f524a4a1c",
    measurementId: "G-NPBWJVRS9M"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
auth.useDeviceLanguage();

// Function is not exported but we want to force proactive refreshing.
try {
    (auth as any)._startProactiveRefresh();
} catch (e) {
    console.error("Proactive refresh failed to start; has the function changed?", e);
}

// A manual way of periodic force-refreshing
function periodicTokenRefresh() {
    try {
        auth.currentUser?.getIdToken?.(); // Not an expensive call
    } catch (e) {
        console.error("Failed to force-refresh user token", e);
    }

    window.setTimeout(periodicTokenRefresh, 5000);
}

periodicTokenRefresh(); // Adds the listener


const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.email');
provider.addScope('https://www.googleapis.com/auth/userinfo.profile');


function startGoogleAuthFlow() {
    signInWithPopup(auth, provider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (!credential) {
                console.error("how did we get no credential?");
            }

            // const token = credential!.accessToken;
            // const user = result.user;
            // getAdditionalUserInfo(result)
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("Sign-in failed!");
            console.error(errorCode, errorMessage);
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
        });
}

export interface AuthSnapshot {
    loading: boolean,
    user: User | null,
    token: string | null,
}

let authSnapshot: AuthSnapshot = {
    loading: true,
    user: auth.currentUser,
    token: null,
};


const authExternalStore = {
    subscribe(callback: any) {
        const updateUserAndFetchToken = (user?: User | null) => {
            if (!user) {
                authSnapshot = {
                    ...authSnapshot,
                    loading: false,
                    user: null,
                    token: null,
                };

                callback();
                return;
            }

            user?.getIdToken(false).then(token => {
                authSnapshot = {
                    ...authSnapshot,
                    loading: false,
                    user,
                    token,
                };

                callback();
            });
        };

        const unsub = auth.onAuthStateChanged(user => {
            if (authSnapshot.user !== user) {
                updateUserAndFetchToken(user);
            } else {
                console.warn("User did not change despite callback.", authSnapshot.user, user);

                // We still want to refresh the token...
                user?.getIdToken(false).then(token => console.log("Token changed to", token))
            }
        });

        auth.onIdTokenChanged(user => {
            console.debug("auth.onIdTokenChanged", user);

            updateUserAndFetchToken(user);
        })

        // TODO: do we not set `ready` too early?
        auth.authStateReady().then(() => updateUserAndFetchToken(authSnapshot.user));

        return () => {
            unsub();
        };
    },

    getSnapshot() {
        return authSnapshot;
    }
}

function useAuth() {
    return useSyncExternalStore(
        authExternalStore.subscribe,
        authExternalStore.getSnapshot,
    );
}

(window as any).signOut = () => auth.signOut();
(window as any).getAuth = () => authExternalStore.getSnapshot();


export { app, auth, startGoogleAuthFlow, useAuth, authSnapshot };
