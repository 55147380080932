import React, { useMemo, memo } from "react";
import { Col, Row } from "antd";
import CollapsingCard from "./CollapsingCard";
import { LockButton, loraPathComparator } from "./Inference";
import { ActiveLora } from "./ActiveLora";
import { LoraCard } from "./LoraCard";
import { nsfw } from "../api";

export const LoraLibrary = memo(function LoraLibrary(props) {
    const { styleModels, activeLoras, setActiveLoras, locked, setLocked } = props;

    const sortedModels = useMemo(() => [...Object.values(styleModels)].sort(loraPathComparator), [styleModels]);
    const activeKeys = useMemo(() => activeLoras.map(m => m.path), [activeLoras]);
    const visibleModels = useMemo(() => nsfw ? [...sortedModels] : sortedModels.filter(m => !m.args.nsfw), [sortedModels]);

    const activeList = activeLoras.map(m => (
        <ActiveLora
            key={m.path}
            m={m}
            styleModels={styleModels}
            setActiveLoras={setActiveLoras} />
    ));

    return (<>
        <CollapsingCard title={<><span style={{ fontSize: 12 }}>Active</span> <LockButton {...{ locked, setLocked }} size="small" /></>} size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a', marginBottom: 8 }}>
            <Row gutter={[16, 16]} align="middle" justify={"center"}>
                <Col span={24}>
                    {activeList.length ? activeList : 'No active styles.'}
                </Col>
            </Row>
        </CollapsingCard>

        <CollapsingCard title={<span style={{ fontSize: 12 }}>Available ({visibleModels.length}/{sortedModels.length})</span>} size="small" bodyStyle={{ background: '#262a33', minHeight: 0, overflowY: 'scroll', maxHeight: 400 }} style={{ background: '#0e1015', border: '1px solid #343d4a' }}>
            <Row gutter={[4, 4]} align="middle" justify={"center"}>
                {visibleModels.map(m => (
                    <LoraCard
                        key={m.path}
                        lora={m}
                        setActiveLoras={setActiveLoras}
                        enabled={activeKeys.includes(m.path)}
                    />
                ))}
            </Row>
        </CollapsingCard>
    </>);
}, (prev, next) =>
    prev.activeLoras === next.activeLoras &&
    prev.styleModels === next.styleModels &&
    prev.setActiveLoras === next.setActiveLoras &&
    prev.locked === next.locked
);
