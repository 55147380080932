import React, { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Layout, ConfigProvider, Col, Row, Modal, Button, Slider, Dropdown, Space, Input, Badge } from "antd/es";
import ReactTimeago from "react-timeago";

import appTheme from "../theme";
import RecentResults from "./RecentResults";
import { AimOutlined, BarsOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";

import "../style/app-shelf.scss";
import { HoverProvider } from "./Hoverable";
import { deleteAllQueuedTrainingTasks, deleteTrainingTask, getQueuedTrainingTasks, setNsfw, setTrainingPriority } from "../api";
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownFilled, CaretDownOutlined, CaretUpFilled, CaretUpOutlined, CloseOutlined, EditOutlined, FileTextOutlined, HourglassOutlined, InfoCircleOutlined, PlusOutlined, UpOutlined } from "@ant-design/icons/es";
import { TrainingSetup } from "./TrainingSetup";
import CollapsingCard from "./CollapsingCard";
import { Radio } from "antd";
import { DatasetsEditor } from "./DatasetsEditor";

const { Header, Content } = Layout;



const App = () => {
    // const [theme, setTheme] = useState(appTheme);

    return (
        <ConfigProvider theme={appTheme}>
            <HoverProvider>
                <MainBody />
            </HoverProvider>
        </ConfigProvider>
    );
}

function useHistory() {
    const [loc, setLoc] = useState(window.location.pathname);

    useEffect(() => {
        const ctrl = new AbortController();

        window.addEventListener("popstate", ev => {
            setLoc(window.location.pathname);
        }, { signal: ctrl.signal });

        return () => ctrl.abort();
    }, [setLoc]);

    const navigateTo = ((path) => {
        window.history.pushState({}, "", path);

        // We need to manually emit this event because the browser doesn't do it.
        window.dispatchEvent(new PopStateEvent('popstate', {}));
    });

    return {
        path: loc,
        go: navigateTo,
    };
}

const Navigation = (props) => {
    const classes = props.visible ? "app-shelf" : "app-shelf-closed";

    return (
        <div className={classes}>
            <MenuButton path="/">Training</MenuButton>
            <MenuButton path="/embeddings/openai">OpenAI TVec</MenuButton>
            <MenuButton path="/captions">Captions</MenuButton>
            <MenuButton path="/datasets">Datasets</MenuButton>
        </div>
    )
}

const Page = (props) => {
    const { children = [], path } = props;

    const hist = useHistory();

    if (hist.path !== path) {
        return <Fragment key={path}></Fragment>
    }

    return (
        <Fragment key={path}>{children}</Fragment>
    )
}

const MenuButton = (props) => {
    const { children = [], path } = props;

    const hist = useHistory();

    const active = hist.path === path;
    const classes = active ? "app-shelf-entry active" : "app-shelf-entry";


    return (
        <div className={classes} onClick={() => hist.go(path)}>
            <AimOutlined />
            <div className="label">{children}</div>
        </div>
    )
}

const MainBody = memo(() => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [deriveArgs, setDeriveArgs] = useState(null);

    return (
        <Layout style={{ minHeight: "100%", height: '100%' }}>
            <AppHeader setDrawerVisible={setDrawerVisible} deriveArgs={deriveArgs} />

            <div className="app-container">
                <Navigation visible={drawerVisible} />

                <Layout style={{ padding: "0 8px 8px", height: '100%' }}>
                    {/* <Breadcrumb style={{ margin: "16px 0" }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Training</Breadcrumb.Item>
                        <Breadcrumb.Item>Images</Breadcrumb.Item>
                    </Breadcrumb> */}

                    <Content style={{ padding: 0, margin: 0, minHeight: 280, color: "#ccc" }}>
                        <Layout style={{ height: '100%' }}>
                            <Row gutter={[12, 12]} style={{ minHeight: 0, marginTop: 8 }}>
                                <Page path="/">
                                    <RecentResults derive={setDeriveArgs} />
                                </Page>

                                <Page path="/embeddings/openai">
                                    <OpenAIEmbeddings />
                                </Page>

                                <Page path="/captions">
                                    <CaptionsTools />
                                </Page>

                                <Page path="/datasets">
                                    <DatasetsEditor />
                                </Page>
                            </Row>
                        </Layout>
                    </Content>
                </Layout>
            </div>
        </Layout>
    );
});

const CaptionsTools = (props) => {
    return (
        <Col xl={24}>
            <Deduplicator />
        </Col>
    )
}

function parseCaptions(raw) {
    const lines = raw.split("\n").map(l => l.trim()).filter(l => l.includes('='));

    const captions = [];
    for (const line of lines) {
        const [k, v] = line.split("=");

        captions.push({ file: k, caption: v });
    }

    return captions;
}

function stringifyCaptions(captions) {
    if (Array.isArray(captions)) {
        return captions.map(({ file, caption }) => `${file}=${caption}`).join('\n');
    } else {
        const lines = [];

        for (const [file, caption] of Object.entries(captions)) {
            lines.push(`${file}=${caption}`);
        }

        return lines.join('\n');
    }
}

function deduplicate(text, mode) {
    const captions = parseCaptions(text);
    const dedupe = {};

    let modeNum = 0;
    switch (mode) {
        case 'first':
            modeNum = 1;
            break;
        case 'shortest':
            modeNum = 2;
            break;
        case 'longest':
            modeNum = 3;
            break;
        default:
            throw "fakka met jou";
    }

    if (!modeNum) {
        return;
    }

    for (const { file, caption } of captions) {
        if (file in dedupe) {
            if (modeNum === 1) { // First
                // Skip, already present
            } else if (modeNum === 2) { // Shortest
                if (dedupe[file].length > caption.length) {
                    dedupe[file] = caption;
                }
            } else if (modeNum === 3) { // Longest
                if (dedupe[file].length < caption.length) {
                    dedupe[file] = caption;
                }
            } else {
                throw "eheheh " + modeNum;
            }
        } else {
            dedupe[file] = caption;
        }
    }

    return stringifyCaptions(dedupe);
}

const Deduplicator = (props) => {
    const [textA, setA] = useState("");
    const [textB, setB] = useState("");

    const [dedupeMode, setDedupeMode] = useState('first');

    function doDeduplicate() {
        setB(deduplicate(textA, dedupeMode));
    }

    return (
        <CollapsingCard title="Caption Toolkit" icon={<FileTextOutlined />}>
            <Row>
                <Col xl={12} style={{ padding: 6 }}>
                    <textarea className="caption-text" placeholder="<input>" value={textA} onChange={e => setA(e.target.value)}></textarea>
                </Col>

                <Col xl={12} style={{ padding: 6 }}>
                    <textarea className="caption-text" readOnly placeholder="<output>" value={textB} onChange={e => setB(e.target.value)}></textarea>
                </Col>
            </Row>

            <Row>
                <Col xl={8} style={{ padding: 6 }}>
                    <CollapsingCard title="Deduplicate">
                        Mode: <Radio.Group value={dedupeMode} onChange={e => setDedupeMode(e.target.value)}>
                            <Radio value="first">Keep first</Radio>
                            <Radio value="shortest">Keep shortest</Radio>
                            <Radio value="longest">Keep longest</Radio>
                        </Radio.Group>

                        <Button onClick={() => doDeduplicate()} type="primary" size="small" style={{ margin: 6, float: 'right' }}>Go!</Button>
                    </CollapsingCard>
                </Col>
            </Row>
        </CollapsingCard>
    )

}

const OpenAIEmbeddings = (props) => {
    const [records, setRecords] = useState([]);
    const [input, setInput] = useState("");
    const [threshold, setThreshold] = useState([-1, 1]);

    const addEmbedding = () => {
        fetch("https://ctlzr-api.bitgate.workers.dev/api/v1/openai-tvec", {
            method: "POST",
            body: JSON.stringify([input]),
            headers: {
                "content-type": "application/json"
            }
        }).then(r => r.json()).then(j => {
            setRecords(all => [{ text: input, data: j.data[0].embedding }, ...all]);
            setInput("");
        });
    };

    const compareEmbeddings = (txtA, txtB) => {
        const objA = records.find(e => e.text === txtA);
        const objB = records.find(e => e.text === txtB);

        if (objA && objB && objA !== objB) {
            const diff = {
                text: `Diff comparison`,
                data: objA.data.map((f, idx) => f - objB.data[idx]),
            };

            setRecords(all => [diff, ...all]);
        }
    };

    const [lower, upper] = threshold;

    return <div className="embeddings-page">
        <div className="embeddings-setup">
            <Input
                className="embeddings-prompt"
                onChange={e => setInput(e.target.value)}
                placeholder="Text to compute embeddings of"
                addonAfter={<Button disabled={!input} type="primary" onClick={addEmbedding} icon={<BarsOutlined />} />}
            />

            {lower} &mdash; {upper}
            <Slider range min={-0.1} max={0.1} step={0.001} defaultValue={[-0.1, 0.1]} onChange={setThreshold} />
        </div>

        <div className="embeddings-container">
            {records.map(emb => (
                <EmbeddingsViewer
                    text={emb.text}
                    data={emb.data}
                    threshold={threshold}
                    allEmbeddings={records}
                    compareEmbeddings={compareEmbeddings}
                />
            ))}
        </div>
    </div>
}

const EmbeddingsViewer = (props) => {
    const { text, data, threshold, allEmbeddings, compareEmbeddings } = props;

    const processed = useMemo(() => {
        const [min, max] = threshold;
        const result = [...data];

        for (let i = 0; i < result.length; i++) {
            const v = result[i];
            if (v < min || v > max) {
                result[i] = 0;
            }
        }

        return result;
    }, [threshold, data]);

    const items = allEmbeddings.filter(o => o.text !== text).map(emb => ({
        label: emb.text,
        key: emb.text,
    }));

    const compareWith = (info) => {
        const target = allEmbeddings.find(e => e.text === info.key);
        if (target) {
            compareEmbeddings(text, target.text);
        }
    };

    return (<div className="embedding"><CollapsingCard title={text}>
        {/* <div className="embedding-text">{text}</div> */}
        <FloatHeatmap data={processed} />

        <Dropdown menu={{ items, onClick: compareWith }}>
            <Button>
                <Space>
                    Compare with...
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    </CollapsingCard>
    </div>)
}

const FloatHeatmap = (props) => {
    const { width = 48, height = 32, data } = props;
    const rows = useMemo(() => generateHeatmap(data, height, width), [data, height, width]);

    if (width * height !== data.length) {
        return <div>Expected {width * height} floats for a {width}x{height} grid, but got {data.length}.</div>
    }

    return (<div className="float-heatmap sm">
        <table>
            <thead></thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    </div>)
}

function generateHeatmap(data, height, width) {
    let rows = [];

    for (let y = 0; y < height; y++) {
        let cols = [];

        for (let x = 0; x < width; x++) {
            const f = data[y * width + x] * 1;

            cols.push(<td key={x}><div className={`v_${(f * 100).toFixed(0)}`}>{f.toFixed(2)}</div></td>);
        }

        rows.push(<tr key={y}>{cols}</tr>);
    }

    return rows;
}

const AppHeader = ({ setDrawerVisible, deriveArgs }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (deriveArgs) {
            setIsModalOpen(true);
        }
    }, [deriveArgs]);

    return <>
        <Header className="header">
            <div className="app-menu-toggle" onClick={() => setDrawerVisible(v => !v)}>
                <BarsOutlined />
            </div>

            <div className="logo">
                <span className='logo-effect'>
                    Bitgate
                </span>

                <span style={{ fontSize: 20, userSelect: 'none' }} onClick={() => setNsfw(true)}>.ai</span>
            </div>

            <div className="menu-right">
                <div className="app-plus" onClick={() => setIsModalOpen(true)}>
                    <PlusOutlined />
                </div>

                <TrainingQueue />
            </div>
        </Header>

        <TrainingSetup
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            deriveArgs={deriveArgs}
        />
    </>
}

const TrainingQueue = (props) => {
    const [queue, setQueue] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getQueuedTrainingTasks().then(v => setQueue(v));

        const interval = setInterval(() => {
            getQueuedTrainingTasks().then(v => setQueue(v));
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    const refreshQueue = useCallback(async () => {
        const q = await getQueuedTrainingTasks();
        setQueue(q);
    }, []);

    return (
        <>
            <div className="app-plus app-plus--invert" onClick={() => setIsModalOpen(true)}>
                <HourglassOutlined />

                <Badge count={queue.length} size="small" />
            </div>

            <TrainingQueueModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                queue={queue}
                refreshQueue={refreshQueue}
            />
        </>
    );
}

const TrainingQueueModal = (props) => {
    const { isOpen, setIsOpen, queue, refreshQueue } = props;
    const [viewing, setViewing] = useState(null);

    const view = (taskId) => {
        setViewing(taskId);
    }

    const edit = (taskId) => {
        console.log('Editing', taskId);
    }

    const cancel = (taskId) => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            deleteTrainingTask(taskId).then(() => refreshQueue());
        }
    }

    const truncateQueue = () => {
        if (window.confirm('Are you sure you want to delete all queued tasks?')) {
            deleteAllQueuedTrainingTasks().then(() => {
                refreshQueue();
                setIsOpen(false);
            });
        }
    }

    const maxPrio = useMemo(() => {
        return queue.reduce((max, b) => Math.max(max, b.priority ?? 1), 0)
    }, [queue]);

    return (
        <Modal title="SDXL Training Queue"
            centered
            width={800}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            className="training-modal"
            styles={{ body: { maxHeight: '75vh', overflowY: 'auto' } }}
            footer={(orig) =>
                <TrainingQueueFooter orig={orig}>
                    <Button type="text" danger onClick={truncateQueue}>Truncate</Button>
                </TrainingQueueFooter>
            }
        >
            <div className="training-queue">
                {queue.filter(task => !task.processing).map((task, i) => (
                    <TrainingQueueEntry
                        key={task.key}
                        task={task}
                        view={view}
                        edit={edit}
                        cancel={cancel}
                        refreshQueue={refreshQueue}
                        maxPrio={maxPrio}
                        isNextInQueue={i === 0}
                    />
                ))}

                <div className="execution-order-indicators">
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                    <ArrowDownOutlined />
                </div>
            </div>
        </Modal>
    )
}

const TrainingQueueFooter = ({ orig, children }) => {
    return (
        <div className="expanded-footer">
            <div className="expanded-footer--children">
                {children}
            </div>

            <div className="expanded-footer--orig">
                {orig}
            </div>
        </div>
    );
};

const TrainingQueueEntry = (props) => {
    const { task, refreshQueue, maxPrio, isNextInQueue, view, edit, cancel } = props;

    const { data, key, priority = 1 } = task;
    const { args } = JSON.parse(data);

    const setPriority = (newPriority) => {
        setTrainingPriority(key, newPriority).then(() => refreshQueue());
    };

    return (
        <div className="training-queue-entry">
            <div className="training-queue-entry__title-group">
                <div className="training-queue-entry__title">
                    {args.captions_file}

                    <div className="training-queue-entry__time">
                        <ReactTimeago date={key} />
                    </div>
                </div>

                <div className="training-queue-entry__subtitle">
                    {args.validation_prompt}
                </div>
            </div>

            <div className="training-queue-entry__suffix">
                <div className="training-queue-entry__actions">
                    <div className="priority-change">
                        <Button size="small" type="default" onClick={() => setPriority(priority - 1)} icon={<CaretDownFilled />} />
                        <div className="priority-value">{priority}</div>
                        <Button size="small" type="default" onClick={() => setPriority(priority + 1)} icon={<CaretUpFilled />} />

                        <Button size="small" type="default" onClick={() => setPriority(maxPrio + 1)} icon={<ArrowUpOutlined />} disabled={isNextInQueue} />
                    </div>

                    <Button size="small" type="primary" onClick={() => view(key)} icon={<InfoCircleOutlined />} title="View" />
                    <Button size="small" type="primary" onClick={() => edit(key)} icon={<EditOutlined />} title="Edit" />
                    <Button size="small" type="primary" onClick={() => cancel(key)} danger icon={<CloseOutlined />} title="Delete" />
                </div>
            </div>
        </div>
    );
}


export default App;