
import { useAuth, startGoogleAuthFlow, authSnapshot } from '../authentication';
import { createContext, useContext } from 'react';

import '../style/auth.scss';

const AuthContext = createContext(authSnapshot);
const AuthProvider = AuthContext.Provider;


export function useUser() {
    return useContext(AuthContext);
}

// Not exported, only used locally.
const SignIn = (props: any) => {
    return <div className='main-container theme-dark'>
        <div className='auth-wrapper'>
            <div>
                <h1>SIGN IN</h1>

                <button className='google-sign-in' onClick={startGoogleAuthFlow}>
                    <div className='icon'>🔒</div>
                    <div className='btn-text'>Sign In with Google</div>
                </button>
            </div>
        </div>
    </div>
}

export const Authenticated = (props: any) => {
    const { children } = props;

    const auth = useAuth();

    if (!auth || auth.loading) {
        // No point in rendering the SignIn button if we're not even sure 
        // what the actual current status is.
        return null;
    }

    // If we're not signed in, render the authentication widget.
    if (!auth.user) {
        return <SignIn />
    }

    // If we haven't obtained our token yet, wait until it completes.
    if (!auth.token) {
        return null; // Don't render sign-in.
    }

    // Otherwise, render the actual contents, passing down the provided auth
    // state for consumers down the render graph.
    return (
        <AuthProvider value={auth}>
            {children}
        </AuthProvider>
    )
}