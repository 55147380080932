import React, { useCallback, memo } from "react";
import { Col, Row, Button } from "antd";
import { nsfw } from "../api";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons/es";
import { Slider } from "antd";
import { EMPTY_PIXEL, defaultLoraScales } from "./Inference";
import { LoraWeightSlider } from "./LoraWeightSlider";


export const ActiveLora = memo(function ActiveLora(props) {
    const { styleModels, setActiveLoras, m } = props;

    const imageSrc = styleModels[m.path]?.image_url;
    const isNsfw = styleModels[m.path]?.args?.nsfw;

    let proxied = imageSrc ? `https://training.bitgate.ai/cdn-cgi/image/format=auto,fit=cover,width=128,height=128/${imageSrc}` : EMPTY_PIXEL;
    if (isNsfw && !nsfw) {
        proxied = `https://placehold.co/256x256/000000/ffffff?text=NSFW`;
    }

    const deactivateLora = useCallback(() => {
        setActiveLoras(v => v.filter(x => x.path !== m.path));
    }, [m.path, setActiveLoras]);

    return (
        <Row gutter={[12, 12]} className="active-lora">
            <Col span={24}>
                <Button
                    icon={<MinusOutlined />}
                    size="small"
                    onClick={deactivateLora}
                    type="primary"
                >{m.path}</Button>
            </Col>

            <Col span={6}>
                <img src={proxied} width="100%" className="lora-thumbnail" />

                <div className="lora-close-button" onClick={deactivateLora}>
                    <CloseOutlined className='poepen' />
                </div>
            </Col>

            <Col span={18} className="lora-library--entry">
                <Row gutter={[2, 2]}>
                    {false ? <>
                        <Col flex="0 1 auto">
                            Alpha:
                        </Col>
                        <Col flex="1 0 auto">
                            <Slider min={0} max={2} step={0.05} value={m.scales.unet.down} onChange={value => setActiveLoras(v => v.map(x => x === m ? { ...x, scales: defaultLoraScales(value) } : x))} />
                        </Col>
                    </> : <>

                        <LoraWeightSlider property="down" m={m} setActiveLoras={setActiveLoras} />
                        <LoraWeightSlider property="mid" m={m} setActiveLoras={setActiveLoras} />
                        <LoraWeightSlider property="up" m={m} setActiveLoras={setActiveLoras} />
                    </>}
                </Row>
            </Col>
        </Row>
    );
}, (prev, next) => prev.m === next.m && prev.styleModels === next.styleModels);
