import React, { memo } from "react";
import Timeago from 'react-timeago';

import { SettingFilled, FontSizeOutlined } from "@ant-design/icons";
import { Col, Card, Button } from "antd/es";

import { sortArgs, prepareArgsForRender } from '../util';

import ResponsiveImage from "./ResponsiveImage";
import { useSettings } from "./RecentResults";



const ValidationImage = memo(({ data, modal }) => {
    const { previewMultiplier, hideTime, combineImages, imgIdx } = useSettings()[0];

    const { images: all_images, run_id: run } = data;
    const time = data["@timestamp"];

    all_images.sort();
    const url = `https://public.dreamshaper.io/results/${run}/previews/${all_images[imgIdx]}`//

    let allUrls = [];
    for (const image of all_images) {
        allUrls.push(`https://public.dreamshaper.io/results/${run}/previews/${image}`);
    }

    // Rename argument entries in data.args
    const args = prepareArgsForRender(data.args);

    const icons = [];
    if (args['train_text_encoder']) {
        icons.push(<FontSizeOutlined />);
    }

    const title = <span style={{ fontSize: 12 }}>{`#${data.step}`}</span>;
    // T${data.args.text_rank} U${data.args.rank}

    const extra = (
        <Button className="params-button" type="link" size="small" onClick={() => {
            modal.info({
                width: 800,
                height: '80%',
                maskClosable: true,
                title: "Experiment parameters",
                content: (
                    <div style={{ overflow: 'auto', maxHeight: 900 }}>
                        <pre style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>{JSON.stringify(sortArgs(args), null, 4)}</pre>
                    </div>
                )
            })
        }}>
            <SettingFilled />
        </Button>
    );

    const sizes = {
        xxl: 3 * previewMultiplier,
        xl: 3 * previewMultiplier,
        md: 3 * previewMultiplier,
        sm: 6 * previewMultiplier,
        xs: 12 * previewMultiplier
    };

    return (
        <Col {...sizes}>
            <Card className="image-card" title={title} extra={extra}>
                <div>
                    {combineImages ? (
                        <div className="image-wrapper" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {allUrls.map((url, idx) =>
                                <ResponsiveImage key={idx} width={'33%'} src={url} />
                            )}
                        </div>
                    ) :
                        <ResponsiveImage src={url} width={'100%'} />
                    }

                    {!hideTime && <div className="time-ago">
                        <Timeago style={{ fontSize: 11 }} date={new Date(time)} live={true} minPeriod={1} maxPeriod={1} />
                    </div>}

                    <div className="icons">
                        {icons}
                    </div>
                </div>
            </Card>
        </Col>
    );
}, newPropsAreEqual);

function newPropsAreEqual(prev, next) {
    const { data: data1 } = prev;
    const { data: data2 } = next;

    if (JSON.stringify(data1) !== JSON.stringify(data2)) {
        console.log('Not the same image data.', data1, data2);
        console.log(JSON.stringify(data1));
        console.log(JSON.stringify(data2));
    }

    return JSON.stringify(data1) === JSON.stringify(data2);
}

export default ValidationImage;