import React, { useState, useEffect } from "react";
import { Button } from "antd/es";

import { putRunArgs, fetchRunArgs, logArgumentModified } from "../api";

const ArgsEditor = (props) => {
    const [args, setArgs] = useState('{}');
    const [originalArgs, setOriginalArgs] = useState({});
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");

    const fetchRun = async (runId) => {
        setLoading(true);
        console.log("Fetchy");

        try {
            const runArgs = await fetchRunArgs(runId);

            // Sort keys alphabetically
            const sorted = {};
            Object.keys(runArgs)
                .sort()
                .forEach((k) => (sorted[k] = runArgs[k]));

            setArgs(JSON.stringify(sorted, null, 4));
            setOriginalArgs(runArgs);
        } catch (error) {
            console.error("Error fetching run args:", error);
            setStatus("Error fetching run args: " + error);
        }

        setLoading(false);
    };

    const saveRun = async (runId, newArgs) => {
        setLoading(true);

        try {
            const obj = JSON.parse(newArgs);

            if (obj) {
                await putRunArgs(runId, obj);
                await logArgumentModified(runId, originalArgs, obj);
                setStatus("Saved!");
                setArgs(JSON.stringify(obj, null, 4));
                setOriginalArgs({ ...obj });
            } else {
                setStatus("Invalid JSON");
            }
        } catch (error) {
            console.error("Error saving run args:", error);
            setStatus("Error saving run args: " + error);
        }

        setLoading(false);
    };

    const changeArgs = e => {
        try {
            setArgs(e.target.value);
        } catch (e) {
            //
        }
    };

    useEffect(() => {
        fetchRun(props.runId);
    }, [props.runId]);

    return (
        <div className="args-editor">
            <textarea value={args} onChange={changeArgs} rows={48} />

            <div className="button-row">
                <Button loading={loading} onClick={() => fetchRun(props.runId)} type="default">Load</Button>
                <Button loading={loading} onClick={() => saveRun(props.runId, args)} type="primary">Save</Button>
            </div>

            {status}
        </div>
    );
}

export default ArgsEditor;