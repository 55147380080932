import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.scss';
import Application from './components/Application';
import { Authenticated } from './components/Authenticated';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <Authenticated>
            <Application />
        </Authenticated>
    </React.StrictMode>
);
