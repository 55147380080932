import React, { useState, useEffect, useCallback, memo } from "react";
import { fetchImageHistory, getEvalTasks } from "../api";

import { SearchOutlined, InstagramOutlined } from "@ant-design/icons/es";
import { Button, Col, Row, Card, Input, Pagination, Tag, Switch } from "antd/es";

import CardTitle from "./CardTitle";

import '../style/mediabrowser.scss';
import { useInterval } from "../util";
import { Hoverable } from "./Hoverable";
import modelStorage from "../store/model-storage";


const MediaBrowser = (props) => {
    const { loadArgs, setViewing, onGenerated, setBaseImage } = props;

    const [history, setHistory] = useState([]);
    const [totalMatches, setTotalMatches] = useState(0);
    const [query, setQuery] = useState('');
    const [collapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState(1);
    const [evalQueue, setEvalQueue] = useState([]);

    const imageFilter = modelStorage.useImageFilter();
    const setImageFilter = modelStorage.useSetImageFilter();
    const [showEvals, setShowEvals] = useState(false);

    const updateHistory = useCallback(async () => {
        const q = query.trim();

        let filters = { 'is_eval': showEvals ? true : null };
        if (imageFilter) {
            filters['model.keyword'] = imageFilter;
        }

        const { values, total } = await fetchImageHistory(36, filters, q, (page - 1) * 36);
        setHistory(values || []);
        setTotalMatches(total);
    }, [query, page, imageFilter, showEvals]);

    useInterval(() => updateHistory(), 10000, false, [updateHistory]);

    useInterval(() => {
        getEvalTasks().then(q => setEvalQueue(q.filter(e => !e.result)));
    }, 10000, false, [setEvalQueue]);


    const images = history.filter(h => h?.inference?.images)
        .map(h => ({ ...h.inference, '_timestamp': h['@timestamp'] }))
        .map(e => ({ args: e, url: e.images[0] }));

    const selectImage = (args, url) => {
        loadArgs(args);
        setViewing(url);
    };

    useEffect(() => {
        onGenerated(() => () => updateHistory);
    }, [onGenerated, updateHistory]);

    const bodyStyle = { padding: '0 !important' };

    if (collapsed) {
        bodyStyle.display = 'none';
    }

    return (
        <Card bodyStyle={bodyStyle} title={<CardTitle icon={<InstagramOutlined />} collapsed={collapsed} setCollapsed={setCollapsed}>Media Browser <span className="subtitle">{evalQueue.length} eval{evalQueue.length === 1 ? '' : 's'} queued</span></CardTitle>} className="run-container settings-card" style={{ height: '100%' }}>
            <Row>
                <Col span={24}>
                    <Input
                        style={{ marginBottom: 8 }}
                        placeholder="Search..."
                        suffix={<span style={{ fontSize: 12, color: 'var(--text-muted)' }}>{totalMatches} results</span>}
                        value={query}
                        onChange={e => { setQuery(e.target.value); setPage(1); }}
                        addonBefore={<SearchOutlined style={{ padding: '0 8px' }} />}
                        addonAfter={<Switch checked={showEvals} onChange={e => setShowEvals(e)} title="Show evals" />}
                    />

                    {imageFilter && <div className="image-browser-model-filter">
                        <Tag color="blue" closable onClose={() => setImageFilter(null)}>{imageFilter}</Tag>
                    </div>}
                </Col>
            </Row>

            <Row>
                <Col span={24} style={{ textAlign: 'center', marginBottom: 8 }}>
                    <Pagination
                        position="bottom"
                        total={totalMatches}
                        current={page}
                        pageSize={36}
                        onChange={setPage}
                        showQuickJumper={false}
                        showSizeChanger={false}
                        showTotal={false}
                        align="center"
                    />
                </Col>
            </Row>

            <Row gutter={[2, 2]} justify={"start"} align={"left"}>
                <ImageThumbGrid
                    images={images}
                    selectImage={selectImage}
                    setBaseImage={setBaseImage}
                />
            </Row>

            <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: 8 }}>
                    <Pagination
                        position="bottom"
                        total={totalMatches}
                        current={page}
                        pageSize={36}
                        onChange={setPage}
                        showQuickJumper={false}
                        showSizeChanger={false}
                        showTotal={false}
                        align="center"
                    />
                </Col>
            </Row>
        </Card>
    )
}

const ImageThumbGrid = memo(function ImageThumbGrid(props) {
    const { images, selectImage, setBaseImage } = props;

    console.log("Re-render");

    return (<>
        {images.map(({ args, url }) => (
            <ImageThumb
                key={args._timestamp}
                args={args}
                image={url}
                onClick={() => selectImage(args, url)}
                setBaseImage={setBaseImage}
            />
        ))}
    </>)
}, areImageThumbGridPropsEqual);

function areImageThumbGridPropsEqual(prev, next) {
    if (prev.images === next.images) {
        console.log("SHORTCUT");
        return true;
    }

    if (prev.images?.length !== next.images?.length) {
        console.log("SHORT CUTER")
        return false;
    }

    for (let i = 0; i < prev.images.length; i++) {
        const a = prev.images[i];
        const b = next.images[i];

        if (a !== b) {
            console.log("YE IT BRICKED!!", JSON.stringify(a) === JSON.stringify(b));
            return false;
        }
    }

    const start = performance.now();
    const eq = JSON.stringify(prev.images) === JSON.stringify(next.images);

    console.log(eq, performance.now() - start);
    return eq;
}

const ImageThumb = function ImageThumb(props) {
    const { image, args, onClick, setBaseImage } = props;
    // const [loadedFor, setLoadedFor] = useState('');

    const onContextMenu = e => {
        e.preventDefault();
        setBaseImage(image);
        return false;
    };

    let url = image;
    let thumb = image;

    if (args.has_thumbs) {
        url = image.replace('.png', '--hq.webp');
        thumb = image.replace('.png', '--thumb256.webp');
    }

    return (
        <Hoverable component={<HoverPreview image={url} args={args} />}>
            <Col xxl={4} xs={4} className="inference-image-thumb-wrapper" onContextMenu={onContextMenu}>
                <img draggable={false} className="inference-image-thumb" src={thumb} width="100%" onClick={onClick} />
                <span className="inference-image-thumb-footer">{args.steps} @ {args.cfg}, {args.scheduler} <span style={{ float: 'right' }}>{args.seed.toString(36)}</span></span>
            </Col>
        </Hoverable>
    );
};

const filtered = ['images', 'num', 'subdir', 'name'];

const HoverPreview = (props) => {
    const { image, args } = props;

    return (<div className="image-preview-container">
        <div className="image-preview-main">
            <img src={image} width={'100%'} style={{ maxWidth: 384, maxHeight: 384 }} />

            <div className="image-preview-footer">
                {args.prompt}
            </div>
        </div>

        <ParameterList args={args} />
    </div>);
}

export const ParameterList = (props) => {
    const { args } = props;

    return (
        <div className="image-preview-params">
            {Object.keys(args).filter(k => !filtered.includes(k)).sort().map(k => (
                <Parameter key={k} name={k} value={args[k]} />
            ))}
        </div>
    )
}

const Parameter = (props) => {
    const { name, value } = props;
    if (name === "loras") {
        return <LoraParameter name={name} value={value} />
    }

    return (
        <div className="param-row">
            <span className="param-name">{name}</span>
            <span className="param-value">{(value ?? "").toString()}</span>
        </div>
    )
}

const LoraParameter = (props) => {
    const { name, value } = props;
    return (
        <div className="param-row">
            <span className="param-name">{name}</span>
            <span className="param-value">{JSON.stringify(value ?? "").toString()}</span>
        </div>
    )
}



export default MediaBrowser;