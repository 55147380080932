import { useCallback, useEffect, useState, useMemo } from "react";
import { createDataset, listDatasets, updateDataset } from "../datasets";
import CollapsingCard from "./CollapsingCard";
import { Button, Col, List, Row } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";

const paginationConfig = {
    position: 'bottom',
    simple: true,
    align: 'center',
    size: 'small',
    showQuickJumper: false,
    totalBoundaryShowSizeChanger: 50000,
    pageSize: 20
};

export const DatasetsEditor = (props) => {
    const [datasets, setDatasets] = useState([]);
    const [editing, setEditing] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [params, setParams] = useState(null);
    const [saving, setSaving] = useState(false);

    const updateDatasets = useCallback(() => {
        listDatasets().then(setDatasets);
    }, []);

    useEffect(() => {
        updateDatasets();
    }, [updateDatasets]);

    const valid = useMemo(() => {
        try {
            JSON.parse(params);
            return true;
        } catch (e) {
            return false;
        }
    }, [params]);

    const edit = (id, params) => {
        setEditing(id);
        setEditingId(id);
        setParams(JSON.stringify(params, null, 4));
    };

    const duplicate = (id, params) => {
        setEditing(id);
        setEditingId(null);
        setParams(JSON.stringify(params, null, 4));
    };

    const changeParams = (params) => {
        setParams(params);
    }

    const overwrite = () => {
        setSaving(true);
        updateDataset(editingId, JSON.parse(params))
            .catch(e => window.alert(e))
            .finally(() => { setSaving(false); updateDatasets() });
    };

    const create = () => {
        setSaving(true);
        createDataset(editingId, JSON.parse(params))
            .catch(e => window.alert(e))
            .finally(() => { setSaving(false); updateDatasets() });
    }


    return (<Col span={24}>
        <Row gutter={[16, 16]} align="middle" justify={"center"}>
            <Col lg={4}>
                <CollapsingCard title={<><span style={{ fontSize: 12 }}>Datasets</span></>} size="small" bodyStyle={{ background: '#262a33', maxHeight: '90vh', overflowY: 'auto' }} style={{ background: '#0e1015', border: '1px solid #343d4a', marginBottom: 8 }}>
                    <List
                        size={"small"}
                        rowKey="id"
                        loading={datasets.length === 0}
                        // pagination={paginationConfig}
                        dataSource={datasets}
                        renderItem={(d, idx) => <List.Item
                            actions={[
                                <Button onClick={() => edit(d.id, d.parameters)} size="small" icon={<EditOutlined />} />,
                                <Button onClick={() => duplicate(d.id, d.parameters)} size="small" icon={<CopyOutlined />} />,
                            ]}
                        >
                            <Dataset data={d} />
                        </List.Item>}
                    />
                </CollapsingCard>
            </Col>

            <Col lg={20}>
                {editing && <>
                    <CollapsingCard title={<><span style={{ fontSize: 12 }}>Editing</span></>} size="small" bodyStyle={{ background: '#262a33' }} style={{ background: '#0e1015', border: '1px solid #343d4a', marginBottom: 8 }}>
                        <div style={{ padding: 12 }}>
                            <h2 style={{ margin: 0 }}>{editing}</h2><br />
                            <ParamEditor args={params} changeArgs={changeParams} />

                            <hr />

                            Dataset ID: <input type="text" value={editingId} onChange={e => setEditingId(e.target.value)} placeholder="Dataset key (ID)" />
                            <br />
                            {!valid && <>Invalid JSON</>}
                            <br />
                            {
                                datasets.find(e => e.id === editingId) ?
                                    <Button disabled={saving || !valid} danger onClick={overwrite}>Overwrite `<b>{editingId}</b>`</Button> :
                                    <Button disabled={saving || !valid} type="primary" onClick={create}>Create `<b>{editingId}</b>`</Button>
                            }
                        </div>
                    </CollapsingCard>
                </>}
            </Col>
        </Row>
    </Col>)
};


const Dataset = (props) => {
    const { id, time, parameters } = props.data;
    return (<>{id}</>)
};

const ParamEditor = (props) => {
    const { args, changeArgs } = props;

    const readonly = false;
    const reformat = () => { };

    return (
        <textarea
            value={args}
            onChange={e => changeArgs(e.target.value)}
            rows={32}
            onBlur={reformat}
            readOnly={readonly}
            style={{ width: '100%' }}
        />
    );
}